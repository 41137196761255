import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Pagination, Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import ListViewToggle from 'src/components/ListViewToggle';
import Loading from 'src/components/loading/Loading';
import MarketingBanner from 'src/components/MarketingBanner';
import QueryParams from 'src/services/QueryParamsService';
import Store from 'src/stores/RootStore';
import type { TProductModel } from 'src/stores/types/ProductTypes';
import UserStore from 'src/stores/UserStore';
import AnalyticsEcommerce, { ImpressionData } from 'src/utils/analytics-ecommerce';
import { SeriesList } from 'src/utils/constants';
import gup from 'src/utils/getUrlQuery';
import ProductFilters from './ProductFilters';
import ProductGrid from './ProductGrid';
import ProductList from './ProductList';

interface OrderPageProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
}

const ListProducts = inject(
    'store',
    'user',
)(
    observer(({ store, user, classes }: OrderPageProps) => {
        const [listView, setListView] = useState(false);
        const [currentPage, setCurrentPage] = useState(0);

        const lang = user!.preferredLang;

        const { filteredBikeList, itemsPerPage, seriesFilter, textFilter } = store!.products;

        // Current filters values from url
        const currentFiltersValues = useMemo(
            () =>
                QueryParams.getQueryParams(['serie', 'search', 'sizes', 'availability', 'itemsPerPage', 'inStockOnly']),
            [],
        );

        const navigate = useNavigate();

        const paginatedProducts = useMemo(() => {
            const paginate = (array: TProductModel[], pageSize: number, pageNumber: number) => {
                return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
            };

            return paginate(filteredBikeList, itemsPerPage, currentPage);
        }, [currentPage, filteredBikeList, itemsPerPage]);

        useEffect(() => {
            if (!store?.products.loading && !store!.products.bikes.size) {
                resetPagination();
                store!.products.loadProducts('');
            }
        }, [store]);

        useEffect(() => {
            store!.marketing.setActiveMarketingTool('BANNER');
            store!.marketing.getMarketingBanners(lang);
        }, [store, lang]);

        // Set the filters values from the url to store for the first render
        useEffect(() => {
            store?.products.setInitProductFilters({
                textFilter: currentFiltersValues.search,
                seriesFilter: currentFiltersValues.serie,
                selectedSizesFilter:
                    typeof currentFiltersValues.sizes === 'string'
                        ? [currentFiltersValues.sizes]
                        : currentFiltersValues.sizes,
                selectedAvailabilityFilter: currentFiltersValues.availability,
                itemsPerPage: currentFiltersValues.itemsPerPage,
                hideDeprecatedFilter: currentFiltersValues.inStockOnly === 'true' ? true : undefined,
            });
        }, [store, currentFiltersValues]);

        useEffect(() => {
            const bikeHpCode = gup('hp');
            const batteryQueryParam = gup('battery');

            if (bikeHpCode) {
                store!.products.setSelectedBikeHpCode(bikeHpCode);
                if (batteryQueryParam) {
                    store!.products.setSelectedBikeBatteryCode(batteryQueryParam, navigate);
                }
                store!.products.toggleProductDetailDialog();
            }
        }, [store, navigate]);

        useEffect(() => {
            if (!store!.products.loading) {
                const products = paginatedProducts;

                // Sending impression data to GA
                AnalyticsEcommerce.getInstance()
                    .clear()
                    .with(instance => {
                        products.forEach((product, index) => {
                            const impression: ImpressionData = {
                                id: product.id,
                                name: product.name,
                                list: 'Products List',
                                brand: 'QWIC',
                                category: `${product.serie}/${product.model}`,
                                variant: product.productInformation?.color || '',
                                position: index + 1,
                                price: product.getPrice(),
                            };

                            instance.addImpression(impression);
                        });
                    })
                    .sendPageView();
            }
        }, [store, paginatedProducts]);

        // remove serie filters if text filter is active
        useEffect(() => {
            if (textFilter.length && !!seriesFilter) {
                store?.products.setSerieFilter('');
            }
        }, [textFilter, seriesFilter, store]);

        useEffect(() => {
            // Reset filters before unmount
            return () => {
                store?.products.resetFilterValues();
                QueryParams.resetQueryParams();
            };
        }, [store]);

        const resetPagination = () => {
            setCurrentPage(1);
        };

        const selectProduct = (productCode: string) => {
            const product = store!.products.bikes.get(productCode)!;

            store!.products.setSelectedBikeHpCode(product.id);

            // resetting the battery also resets the price
            store!.products.setSelectedBikeBatteryCode('');
            store!.products.toggleProductDetailDialog();
        };

        return (
            <>
                <div className={classes.categoriesFilterContainer}>
                    <div className={classes.seriesContainer}>
                        {Object.keys(SeriesList).map(seriesKey => {
                            return (
                                <div key={seriesKey} className={classes.seriesBlock}>
                                    {seriesKey === 'Favorites' && <div className={classes.separator} />}
                                    <Button
                                        color={seriesFilter === SeriesList[seriesKey] ? 'secondary' : 'primary'}
                                        onClick={() => store?.products.setSerieFilter(SeriesList[seriesKey])}
                                        data-testid={`serie-filter-${seriesKey.toLowerCase()}`}
                                        disabled={textFilter.length > 0}
                                    >
                                        {seriesKey === 'Favorites' ? (
                                            <div className={classes.seriesButton}>
                                                <FormattedMessage id="labels.favorites" />
                                                <FontAwesomeIcon
                                                    className={classes.favIcon}
                                                    icon={faStarSolid}
                                                    size="sm"
                                                />
                                            </div>
                                        ) : (
                                            // TODO add translation for series rather than using the values directly
                                            seriesKey
                                        )}
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                    <ListViewToggle listView={listView} setListView={() => setListView(!listView)} />
                </div>
                <ProductFilters resetPagination={resetPagination} initSearchFilterValue={currentFiltersValues.search} />
                <MarketingBanner />
                <div className={classes.productContainer}>
                    {!store!.products.loading ? (
                        <>
                            {listView ? (
                                <ProductList products={paginatedProducts} selectProduct={selectProduct} />
                            ) : (
                                <ProductGrid products={paginatedProducts} selectProduct={selectProduct} />
                            )}
                        </>
                    ) : (
                        <Loading small />
                    )}
                </div>
                <div className={classes.paginationContainer}>
                    <Pagination
                        className={classes.pagination}
                        size="small"
                        shape="rounded"
                        boundaryCount={2}
                        color="secondary"
                        count={Math.ceil(store!.products.filteredBikeList.length / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, value) => {
                            setCurrentPage(value);
                        }}
                    />
                </div>
            </>
        );
    }),
);

function styles({ palette }: Theme) {
    return createStyles({
        productContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            paddingBottom: '2em',
            alignItems: 'center',
        },
        paginationContainer: {
            textAlign: 'center',
            marginTop: '1em',
            position: 'absolute',
            bottom: 0,
            left: 240,
            right: 0,
        },
        pagination: {
            background: palette.background.default,
            padding: '0.5em 1em',
            display: 'inline-block',
            boxShadow: '0 0 15px rgba(0,0,0,.15)',
        },
        separator: {
            height: '30px',
            width: '2px',
            margin: '3px .5rem',
            background: palette.primary.light,
        },
        categoriesFilterContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        seriesContainer: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        seriesBlock: {
            display: 'flex',
        },
        seriesButton: {
            display: 'flex',
            alignItems: 'center',
        },
        favIcon: {
            marginLeft: '.2rem',
        },
    });
}

export default withStyles(styles)(ListProducts);
