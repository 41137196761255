import { faBatteryBolt, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Card, Checkbox, FormControl, Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import batteryPlaceholder from 'src/assets/battery-back.svg';
import partPlaceholder from 'src/assets/part.svg';
import ColorDot from 'src/components/ColorDot';
import ComposedProductAvailabilityLabel from 'src/components/composedProductAvailabilityLabel';
import CurrencyFormat from 'src/components/currency/Currency';
import Image from 'src/components/Image';
import InputReference from 'src/components/InputReference';
import ProductAvailabilityLabel from 'src/components/productAvailabilityLabel';
import ProductImage from 'src/components/ProductImage';
import QuantitySelector from 'src/components/QuantitySelector';
import RootStore from 'src/stores/RootStore';
import { TCartItem } from 'src/stores/types/CartItemModel';
import UserStore from 'src/stores/UserStore';
import { ProductCategoryTypes } from 'src/utils/constants';

interface CartItemProps extends WithStyles<typeof styles> {
    item: TCartItem;
    store?: typeof RootStore.Type;
    user?: typeof UserStore.Type;
    error?: boolean;
    removeCartItem: (item: TCartItem) => void;
    viewItem: (item: TCartItem) => void;
}

const CartItem = inject(
    'store',
    'user',
)(
    observer(({ item, classes, store, user, removeCartItem, viewItem }: CartItemProps) => {
        const language = user!.preferredLang;
        const isBike = item.product.type === ProductCategoryTypes.BIKE;
        // treat all non-bike items as selected by default
        const isSelected = !isBike || item.isSelected;
        const imagePlaceholder: string =
            item.product.type === ProductCategoryTypes.BATTERY ? batteryPlaceholder : partPlaceholder;
        const batteryInfo = item.battery ? item.product.batteryInfo(item.battery!.code) : null;
        const saddleAndGripInfo = item.saddleAndGrip
            ? store?.products.getConfigurablePartById(item.id, item.saddleAndGrip)?.productInformation
            : null;
        const strapInfo = item.strap
            ? store?.products.getConfigurablePartById(item.id, item.strap)?.productInformation
            : null;

        return (
            <Card data-testid={`product-cart-${item.id}`} className={classes.card}>
                <Grid container>
                    <Grid
                        item
                        xs={3}
                        className={`${classes.cartImageContainer} ${isBike && classes.selectable} ${
                            isSelected && classes.active
                        }`}
                        onClick={isBike ? item.toggleSelect : undefined}
                    >
                        {isBike ? (
                            <>
                                <div className={classes.checkboxHolder}>
                                    <Checkbox
                                        data-testid="product-cart-checkbox"
                                        disableRipple
                                        checked={item.isSelected}
                                        color="secondary"
                                    />
                                </div>
                                <Box sx={{ paddingTop: '68.75%', position: 'relative' }}>
                                    <Image
                                        src={
                                            item.product!.productInformation.imageUrl
                                                ? item.product!.productInformation.imageUrl.replace(
                                                      '.png',
                                                      '-400x275.png',
                                                  )
                                                : imagePlaceholder
                                        }
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                        }}
                                    />
                                </Box>
                            </>
                        ) : (
                            <ProductImage id={item.product.id} type={item.product.type} />
                        )}
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={0}>
                            <Grid item className={`${!isSelected && classes.disabled}`}>
                                <Typography
                                    className={classes.title}
                                    title={item.product.translatedDescription(language)}
                                    gutterBottom
                                    component="h2"
                                >
                                    {item.product.translatedDescription(language)}
                                </Typography>

                                {item.battery ? (
                                    <Typography
                                        variant="caption"
                                        component="p"
                                        className={classes.additionalItem}
                                        sx={{ marginBottom: '5px' }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faBatteryBolt}
                                            size="1x"
                                            data-testid={`cart-item-battery${
                                                item.battery.code !== 'N/A' ? '' : '-none'
                                            }`}
                                        />
                                        <span>{item.battery.translatedDescription(language)}</span>
                                    </Typography>
                                ) : null}
                                {item.saddleAndGrip ? (
                                    <Typography variant="caption" component="p" className={classes.additionalItem}>
                                        <ColorDot color={saddleAndGripInfo.color} small />
                                        <span>{saddleAndGripInfo.name}</span>
                                    </Typography>
                                ) : null}
                                {item.strap ? (
                                    <Typography variant="caption" component="p" className={classes.additionalItem}>
                                        <ColorDot color={strapInfo.color} small />
                                        <span>{strapInfo.name}</span>
                                    </Typography>
                                ) : null}
                            </Grid>
                            <DeleteIcon
                                onClick={() => removeCartItem(item)}
                                color="disabled"
                                className={classes.deleteButton}
                                data-testid={`remove-item-${item.id}`}
                            />
                        </Grid>

                        <Grid container className={`${classes.prices} ${!isSelected && classes.disabled}`}>
                            <Grid item xs={2}>
                                <QuantitySelector
                                    quantity={item.quantity}
                                    maxQuantity={item.product.productInformation.stockQuantity}
                                    updateQuantity={newQuantity => {
                                        store!.cart.updateCartItem(item, newQuantity);
                                        store!.cart.toggleBundleWarning();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="caption" style={{ marginLeft: '70px' }}>
                                    <FormattedMessage id="cart.unit_price" />
                                </Typography>
                                <Typography variant="body1" align="right">
                                    <CurrencyFormat
                                        value={item.totalProducts(store!.cart.isDesirableDeliveryDateNextYear)}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="caption" style={{ marginLeft: '70px' }}>
                                    <FormattedMessage id="cart.total_price" />
                                </Typography>
                                <Typography variant="body1" align="right">
                                    <CurrencyFormat
                                        value={item.totalItemsPrice(store!.cart.isDesirableDeliveryDateNextYear)}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={`${classes.availability} ${!isSelected && classes.disabled}`}>
                            {isBike ? (
                                <ComposedProductAvailabilityLabel
                                    bikeInfo={item.product.productInformation}
                                    batteryInfo={batteryInfo}
                                    saddleAndGripInfo={saddleAndGripInfo}
                                    strapInfo={strapInfo}
                                />
                            ) : (
                                <ProductAvailabilityLabel
                                    inStock={item.product.productInformation.inStock}
                                    availableFrom={item.product.productInformation.availableFrom}
                                />
                            )}
                        </Grid>
                        {!isSelected && item.reference && (
                            <Grid container className={`${classes.reference} ${!isSelected && classes.disabled}`}>
                                <Typography variant="caption">
                                    <FormattedMessage id="cart.reference" />
                                    <b>{item.reference}</b>
                                </Typography>
                            </Grid>
                        )}
                        <Grid container className={`${classes.detail} ${isSelected ? classes.open : classes.disabled}`}>
                            <Button
                                data-testid={`sparePart-detail-${item.id}`}
                                variant="outlined"
                                onClick={() => viewItem(item)}
                                className={classes.detailButton}
                            >
                                <FormattedMessage id="cart.item-details" />
                                <FontAwesomeIcon icon={faInfoCircle} size="lg" className={classes.buttonIcon} />
                            </Button>

                            <FormControl variant="outlined" fullWidth>
                                <InputReference
                                    reference={item.reference}
                                    updateReference={item.setReference}
                                    unfocus={() => {
                                        store!.cart.updateCartDBSnapshot();
                                    }}
                                    testIdItem={item.id}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        );
    }),
);

function styles({ breakpoints, spacing, palette }: Theme) {
    return createStyles({
        card: {
            flexGrow: 1,
            maxWidth: '100%',
            paddingBottom: '1em',
            margin: '1em 0',
            [breakpoints.down('sm')]: {
                maxWidth: '100%',
                margin: '0 0 10%',
            },
            boxShadow: 'none',
            borderBottom: 'solid 1px #ddd',
            position: 'relative',
        },
        cartImageContainer: {
            paddingRight: spacing(2),
        },
        selectable: {
            cursor: 'pointer',
            opacity: 0.5,
            transition: 'opacity .3s ease',
            '&:hover': {
                opacity: 1,
            },
        },
        active: {
            opacity: 1,
        },
        disabled: {
            opacity: 0.5,
            pointerEvents: 'none',
        },
        buttonIcon: {
            marginLeft: spacing(1),
        },
        detailButton: {
            marginTop: spacing(1),
        },
        deleteButton: {
            position: 'absolute',
            right: 0,
            top: 0,
            cursor: 'pointer',
            transition: 'color .3s ease',
            '&:hover': {
                color: palette.secondary.main,
            },
        },
        title: {
            paddingRight: '2rem',
        },
        prices: {
            margin: '.2rem 0',
        },
        availability: {
            margin: '.5rem 0',
        },
        detail: {
            overflow: 'hidden',
            maxHeight: 0,
            transition: 'all .3s ease',
        },
        open: {
            maxHeight: '400px',
        },
        checkboxHolder: {
            marginLeft: '-10px',
            marginTop: '-10px',
            position: 'absolute',
            zIndex: 10,
        },
        additionalItem: {
            display: 'flex',
            alignItems: 'center',
            lineHeight: 'normal',

            '& > svg': {
                marginRight: spacing(1),
            },
        },
        reference: {
            display: 'flex',
            alignItems: 'center',
            '& b': {
                display: 'inline-block',
                marginLeft: '.5rem',
                fontWeight: 'bold',
            },
        },
    });
}

export default withStyles(styles)(CartItem);
