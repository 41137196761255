import { faArrowToLeft, faArrowToRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';

import AppBarCustom from 'src/components/AppBarCustom';
import MenuListItems from 'src/components/MenuListItems';
import NotificationsList from 'src/components/NotificationsList';
import StorageLoadingErrorSnackbar from 'src/components/snackbars/StorageLoadingErrorSnackbar';
import Service from 'src/services/api/Api';
import { AppModules } from 'src/services/api/models';
import RoleBaseAccess from 'src/services/RoleBaseAccess';
import Store from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import CONFIG from 'src/utils/config';
import { ProductCategoryTypes } from 'src/utils/constants';
import gup from 'src/utils/getUrlQuery';
import useWindowSize from 'src/utils/useWindowSize';
import Cart from './cart/Cart';
import CartReturnsV2 from './cartReturns/CartReturnsV2';
import MarketingMaterialDetailsDialog from './marketingMaterials/MarketingMaterialDetailsDialog';
import NewReturnRequestDialogV2 from './newReturn/NewReturnRequestDialogv2';
import ProductDetailDialog from './products/ProductDetailDialog/ProductDetailDialog';
import Profile from './profile/ProfilePage';
import SparePartDetailDialog from './spareParts/SparePartDetailDialog';
import withRoot from './withRoot';

interface LayoutProps extends PropsWithChildren<WithStyles<typeof styles>> {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
    classes: any;
}

const LayoutPage = inject(
    'user',
    'store',
)(
    observer(({ store, user, classes }: LayoutProps) => {
        const sparePartQueryParam = gup('code');
        const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
        const [isNotificationsDrawerOpen, setIsNotificationsDrawerOpen] = useState(false);
        const [isMenuDrawerClosed, setIsMenuDrawerClosed] = useState(false);
        const [isMenuDrawerEnabled, setIsMenuDrawerEnabled] = useState(true);
        const [activeCartType, setActiveCartType] = useState(
            ProductCategoryTypes.BIKE as ProductCategoryTypes.BIKE | ProductCategoryTypes.PART,
        );
        const [isRefreshSnackbarOpen, setIsRefreshSnackbarOpen] = useState(false);

        // TODO: this causing Layout to rerender 2
        const { width: windowWidth } = useWindowSize();

        // Auto minimize the drawer when the screen is too narrow
        useEffect(() => {
            if (windowWidth && windowWidth < CONFIG.WINDOW_WIDTH_THRESHOLD) {
                setIsMenuDrawerEnabled(false);
            } else {
                setIsMenuDrawerEnabled(true);
            }
        }, [windowWidth]);

        const loadProducts = useCallback(async () => {
            // load bikes in product page
            if (!store!.products.bikes.size && !store?.products.loading) {
                await store!.products.loadProducts('');
            }
            // load bikes for spare parts
            if (!store!.spareParts.bikesInService.size) {
                await store!.spareParts.getBikesInService();
            }
        }, [store]);

        const loadStorageData = useCallback(async () => {
            const loadStorage =
                !store?.favorites.products.size || !store.cart.items.length || !store.cartReturns.items.length;

            if (loadStorage) {
                setIsRefreshSnackbarOpen(false);

                await Service.getStorageData()
                    .then(({ data }) => {
                        if (data && data.productFavorites) {
                            store!.favorites.loadFaves(data.productFavorites);
                        }

                        if (data && data.shopCart) {
                            store!.cart.updateCartFromDb(data.shopCart);
                        }

                        if (data && data.returnsCart) {
                            store!.cartReturns.updateCartFromDb(data.returnsCart);
                        }
                    })
                    .catch(() => {
                        setIsRefreshSnackbarOpen(true);
                    });
            }
        }, []);

        useEffect(() => {
            if (sparePartQueryParam) {
                store!.spareParts.setSelectedSparePartId(sparePartQueryParam);
            }

            const getData = async () => {
                try {
                    // Products should be always load before storage data
                    await loadProducts();
                    await loadStorageData();
                } catch (e) {
                    console.error(`Error retriving Products and Storage data: ${e}`);
                }
            };

            if (user?.userId) {
                Service.setUser(user.userId);
                getData();
            }
        }, [user?.userId]); // eslint-disable-line

        const paperClassName = classNames(classes.drawerPaper, isMenuDrawerClosed && classes.drawerPaperClose);

        return (
            <>
                <CssBaseline />
                <div className={classes.root}>
                    <AppBarCustom
                        openProfileDrawer={() => setIsProfileDrawerOpen(true)}
                        openNotificationsDrawer={() => setIsNotificationsDrawerOpen(true)}
                        isMenuDrawerOpen={!isMenuDrawerClosed}
                    />

                    {isMenuDrawerEnabled && (
                        <Drawer variant="permanent" classes={{ paper: paperClassName }} open={isMenuDrawerClosed}>
                            <div
                                className={classes.toolbarIcon}
                                onClick={() => {
                                    setIsMenuDrawerClosed(!isMenuDrawerClosed);
                                }}
                            >
                                <IconButton size="large">
                                    <FontAwesomeIcon
                                        icon={isMenuDrawerClosed ? faArrowToLeft : faArrowToRight}
                                        size="1x"
                                    />
                                </IconButton>
                                <div className={classes.toolbarText}>
                                    <FormattedMessage id="menu.minimize" />
                                </div>
                            </div>
                            <Divider />
                            <div className={classes.menuList}>
                                <MenuListItems />
                            </div>
                        </Drawer>
                    )}

                    <Drawer
                        variant="temporary"
                        anchor="right"
                        open={isProfileDrawerOpen}
                        classes={{ paper: classes.profile }}
                        onClose={() => setIsProfileDrawerOpen(false)}
                        data-testid="profile-drawer"
                    >
                        <Profile path="profile" closeDrawer={() => setIsProfileDrawerOpen(false)} />
                    </Drawer>

                    <Drawer
                        data-testid="notifications-drawer"
                        variant="temporary"
                        anchor="right"
                        open={isNotificationsDrawerOpen}
                        classes={{ paper: classes.notifications }}
                        onClose={() => {
                            setIsNotificationsDrawerOpen(false);
                            store!.orderNotifications.readAllNotifications();
                        }}
                    >
                        <NotificationsList
                            notifications={store!.orderNotifications.items}
                            calculateUnreadStatus={store!.orderNotifications.isNotificationUnread}
                            closeDrawer={() => {
                                setIsNotificationsDrawerOpen(false);
                                store!.orderNotifications.readAllNotifications();
                            }}
                        />
                    </Drawer>

                    {RoleBaseAccess.isModuleAllowed(AppModules.ORDER_PRODUCTS) && (
                        <Drawer
                            variant="temporary"
                            anchor="right"
                            open={store!.config.cart}
                            classes={{ paper: classes.cart }}
                            onClose={() => {
                                store!.config.toggleCart();
                                store!.cart.toggleBundleWarning(false);
                            }}
                            data-testid="cart-drawer"
                        >
                            <Cart
                                path="cart"
                                activeCartType={activeCartType}
                                setActiveCartType={(type: ProductCategoryTypes.BIKE | ProductCategoryTypes.PART) =>
                                    setActiveCartType(type)
                                }
                            />
                        </Drawer>
                    )}

                    {RoleBaseAccess.isModuleAllowed(AppModules.REQUEST_RETURN_ITEMS) && (
                        <Drawer
                            variant="temporary"
                            anchor="right"
                            open={store!.config.cartReturns}
                            classes={{ paper: classes.cart }}
                            onClose={() => {
                                store!.config.toggleCartReturns();
                                store!.cartReturns.closeBundleWarning();
                            }}
                            data-testid="cart-returns-drawer"
                        >
                            <CartReturnsV2 />
                        </Drawer>
                    )}

                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Outlet />
                    </main>

                    <ProductDetailDialog setCartTypeToBike={() => setActiveCartType(ProductCategoryTypes.BIKE)} />
                    <SparePartDetailDialog
                        setCartTypeToSparePart={() => setActiveCartType(ProductCategoryTypes.PART)}
                    />
                    <MarketingMaterialDetailsDialog
                        setCartTypeToSparePart={() => setActiveCartType(ProductCategoryTypes.PART)}
                    />

                    <NewReturnRequestDialogV2 />

                    <StorageLoadingErrorSnackbar
                        open={isRefreshSnackbarOpen}
                        onRefresh={loadStorageData}
                        onDismiss={() => setIsRefreshSnackbarOpen(false)}
                    />
                </div>
            </>
        );
    }),
);

function styles(theme: Theme): any {
    return createStyles({
        root: {
            display: 'flex',
        },
        toolbar: {
            paddingRight: 24, // keep right padding when drawer closed
        },
        toolbarIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        toolbarText: {
            fontFamily: 'Apercu',
            fontSize: '1.1rem',
            paddingLeft: '5px',
            background: 'none !important',
            opacity: 0.2,
            cursor: 'pointer',
            transition: theme.transitions.create(['opacity'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            '&:hover': {
                opacity: 1,
            },
        },
        drawerPaper: {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: 240,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
        },
        appBarSpacer: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            height: '100vh',
            padding: theme.spacing(3),
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
        },
        cart: {
            width: '450px',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        profile: {
            width: '450px',
            overflow: 'hidden',
        },
        notifications: {
            width: '450px',
            overflow: 'hidden',
        },
        menuList: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
        },
    });
}

export default withRoot(withStyles(styles)(LayoutPage));
