import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, createTheme, Typography, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import IfNot from 'src/components/conditional/IfNot';
import PageLoader from 'src/components/PageLoader';
import FilterInvoicesBar from 'src/pages/modules/invoices/FilterInvoicesBar';
import { defaultTheme } from 'src/pages/modules/withRoot';
import { AppPageRoutes } from 'src/services/api/models';
import PersistentStore from 'src/stores/PersistentStore';
import RootStore from 'src/stores/RootStore';
import { TInvoice } from 'src/stores/types/Invoice';
import UserStore from 'src/stores/UserStore';
import {
    createTableValues,
    customInvoiceSort,
    CustomPaginationFooter,
    TableCell,
    TableColumnTypes,
} from 'src/utils/tableColumnUtils';
import InvoicePaymentDialog from './InvoicePaymentDialog';
import { TableTitle } from './TableTitle';

const customTheme = createTheme(
    deepmerge(defaultTheme, {
        components: {
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: '10px',
                    },
                },
            },
        },
    }),
);

interface InvoicesProps {
    path?: string;
    user?: typeof UserStore.Type;
    store?: typeof RootStore.Type;
    persistentStore?: typeof PersistentStore.Type;
    isUnusedReturnTable?: boolean;
}

const Invoices = inject(
    'store',
    'persistentStore',
    'user',
)(
    observer(({ store, persistentStore, user, isUnusedReturnTable }: InvoicesProps) => {
        const navigate = useNavigate();

        let [searchParams, setSearchParams] = useSearchParams();
        const [isInvoicePaymentDetailOpen, setIsInvoicePaymentDetailOpen] = useState(false);
        const [invoicesTable, setInvoicesTable] = useState<any>(<></>);

        const selectedInvoices = useRef([] as string[]);

        const from = searchParams.get('from');
        const to = searchParams.get('to');

        const minDate = useMemo(() => {
            // show only invoices in the last 6 weeks for unused new return request
            // if (isUnusedReturnTable) {
            //     return dayjs().subtract(6, 'week').toDate();
            // }
            // return from ? dayjs(from).toDate() : dayjs().subtract(2, 'month').toDate();
            // QWIC 2.0 restart
            return new Date('2024-03-01');
        }, [from, isUnusedReturnTable]);

        const maxDate = useMemo(() => {
            return to ? dayjs(to).toDate() : dayjs().toDate();
        }, [to]);

        useEffect(() => {
            const execute = async () => {
                await store!.invoices.getInvoices(minDate, maxDate);

                // TODO: render a Table other way, not here
                setInvoicesTable(renderTable());
            };

            execute();
        }, [minDate, maxDate]);

        const handleDateRangeChange = (dateRange: Date[]) => {
            if (dateRange && dateRange.length > 1) {
                // Writing dates to the url
                setSearchParams({ from: dateRange[0].toISOString(), to: dateRange[1].toISOString() });
            }
        };

        const buildTable = () => {
            return createTableValues(
                store!.invoices.getFilteredInvoices(minDate, maxDate).map((invoice: any): TableCell[] => {
                    // const isPaymentsEnabled = isUnusedReturnTable ? false : user!.paymentsEnabled;
                    return [
                        { id: 'invoiceId', value: invoice.invoiceId, display: 'none' },
                        { id: 'invoiceNumber', value: invoice.name },
                        { id: 'orderNumber', value: invoice.orderCode },
                        { id: 'reference', value: invoice.externalDocument },
                        { id: 'totalAmountExclVAT', value: invoice.totalAmountExclVAT, type: TableColumnTypes.price },
                        { id: 'totalAmountInclVAT', value: invoice.totalAmountInclVAT, type: TableColumnTypes.price },
                        {
                            id: 'invoiceDate',
                            value: dayjs(invoice.createdDate, 'YYYY-MM-DD').toString(),
                            type: TableColumnTypes.date,
                        },
                        { id: 'dueDate', value: invoice.dueDate, type: TableColumnTypes.date },
                        {
                            id: 'paymentStatus',
                            value: invoice,
                            type: TableColumnTypes.custom,
                            customRender: (inv: TInvoice) => {
                                // if (inv.type === InvoiceType.INVOICE || !inv.paymentStatus) {
                                //    return <Typography textAlign="center">-</Typography>;
                                // }

                                // Show payment status for Credit notes
                                return (
                                    <Typography textAlign="center">
                                        <FormattedMessage id={`payment.${inv.open ? 'Open' : 'Paid'}`} />
                                    </Typography>
                                );

                                // Stop showing payment status for invoice
                                // let dotColor: string = dotColorMapInvoicePaymentStatus[inv.paymentStatus];
                                // if (dayjs(inv.dueDate).isBefore(dayjs()) && inv.paymentStatus === 'Open') {
                                //     inv.setAsOverdue();
                                //     dotColor = 'red';
                                // }

                                // return (
                                //     <>
                                //         <span
                                //             style={{
                                //                 height: '10px',
                                //                 width: '10px',
                                //                 backgroundColor: dotColor,
                                //                 borderRadius: '50%',
                                //                 display: 'inline-block',
                                //                 marginRight: '5px',
                                //                 marginTop: '4px',
                                //             }}
                                //         />
                                //         <FormattedMessage id={`payment.${inv.paymentStatus}`} />
                                //     </>
                                // );
                            },
                            // display: isPaymentsEnabled ? 'true' : 'excluded',
                        },
                        {
                            id: 'downloadPDF',
                            value: 'DOWNLOAD',
                            type: TableColumnTypes.custom,
                            customRender: () => (
                                <Button variant="text" color="primary">
                                    <FontAwesomeIcon icon={faDownload} size="lg" />
                                </Button>
                            ),
                            sort: false,
                        },
                    ];
                }),
            );
        };

        // TODO: renderTable and buildtable look like a mess
        const renderTable = () => {
            const table = buildTable();
            const data = table.data;
            const columns = table.columns;

            let options: MUIDataTableOptions = {
                filterType: 'multiselect',
                responsive: 'vertical',
                pagination: true,
                filter: false,
                download: false,
                viewColumns: false,
                rowHover: true,
                elevation: 0,
                sort: true,
                print: false,
                page: persistentStore!.invoicesTable.tablePage,
                onChangePage: persistentStore!.invoicesTable.setTablePage,
                rowsPerPage: persistentStore!.invoicesTable.tableRowsPerPage,
                onChangeRowsPerPage: persistentStore!.invoicesTable.setTableRowsPerPage,
                onCellClick: (colData, cellMeta) => {
                    let invoiceId = '';
                    columns.forEach((column, index) => {
                        if (column.name === 'invoiceId') {
                            invoiceId = data?.[cellMeta.dataIndex]?.[index];
                        }
                    });
                    if (!invoiceId) {
                        return;
                    }
                    if (columns[cellMeta.colIndex] && columns[cellMeta.colIndex].name === 'downloadPDF') {
                        const invoice = store!.invoices.list.get(invoiceId);
                        if (invoice) {
                            invoice.downloadPDF(user!.preferredLang);
                        }
                    } else {
                        store?.invoices.setSelectedInvoiceId(invoiceId);

                        const url = isUnusedReturnTable
                            ? `${AppPageRoutes.INVOICE_DETAILS.replace(
                                  ':invoiceId',
                                  invoiceId,
                              )}?isFromUnusedReturnTable=true`
                            : AppPageRoutes.INVOICE_DETAILS.replace(':invoiceId', invoiceId);
                        navigate(url);
                    }
                },
                // Column indexs effect sort
                customSort: customInvoiceSort,
                customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
                    return (
                        <CustomPaginationFooter
                            rowCount={count}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            changeRowsPerPage={changeRowsPerPage}
                            changePage={changePage}
                        />
                    );
                },
            };

            options.selectableRows = 'none';
            // TODO: temporary hide payment option
            // if (isUnusedReturnTable) {
            //     options.selectableRows = 'none';
            // } else {
            //     options.selectableRows = user!.paymentsEnabled ? 'multiple' : 'none';
            //     options.selectToolbarPlacement = 'replace';
            //     options.customToolbarSelect = () => {
            //         return (
            //             <Button
            //                 data-testid="payInvoiceButton"
            //                 variant="contained"
            //                 onClick={() => setIsInvoicePaymentDetailOpen(true)}
            //             >
            //                 <FormattedMessage id="invoice.paySelectedInvoices" />
            //             </Button>
            //         );
            //     };
            //     options.isRowSelectable = dataIndex => {
            //         const invoice = store!.invoices.getInvoiceByID(data[dataIndex][0]);
            //         return !(
            //             ['Paid', 'Pending'].includes(invoice!.paymentStatus) ||
            //             invoice!.hasNegativeAmount ||
            //             invoice!.isCreditNote
            //         );
            //     };
            //     options.selectableRowsHeader = true;
            //     options.onRowSelectionChange = (
            //         currentRowsSelected: any,
            //         allRowsSelected: { dataIndex: number; index: number }[],
            //     ) => {
            //         selectedInvoices.current = allRowsSelected.map(rowData => data[rowData.dataIndex][0]);
            //     };
            // }

            return (
                <MUIDataTable
                    title={<TableTitle isUnusedReturnTable={isUnusedReturnTable} />}
                    data={data}
                    columns={columns}
                    options={options}
                />
            );
        };

        const paymentsInvoicesData = (): TInvoice[] =>
            store!.invoices.getCheckedInvoices(selectedInvoices.current) as TInvoice[];

        return (
            <>
                {!isUnusedReturnTable && (
                    <InvoicePaymentDialog
                        isOpen={isInvoicePaymentDetailOpen}
                        close={() => setIsInvoicePaymentDetailOpen(!isInvoicePaymentDetailOpen)}
                        selectedInvoicesIds={selectedInvoices.current}
                        getSelectedInvoices={paymentsInvoicesData}
                        invoices={store!.invoices.getAll}
                    />
                )}

                {!isUnusedReturnTable && (
                    <FilterInvoicesBar
                        prefiltered={isUnusedReturnTable}
                        minDate={minDate}
                        maxDate={maxDate}
                        onDateRangeChange={handleDateRangeChange}
                    />
                )}

                {/* TODO Elaborate this usage again and see if it is applicable into other places too (S.Ç) */}
                <IfNot condition={store?.invoices.loading} otherwise={<PageLoader />}>
                    <ThemeProvider theme={customTheme}>{invoicesTable}</ThemeProvider>
                </IfNot>
            </>
        );
    }),
);

export default Invoices;
