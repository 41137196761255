import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ProductAvailabilityLabel from 'src/components/productAvailabilityLabel';
import RootStore from 'src/stores/RootStore';
import { TProductModel } from 'src/stores/types/ProductTypes';

interface SelectStrapProps {
    store?: typeof RootStore.Type;
    selectedStrapCode: string;
    setSelectedStrapCode: (selected: string) => void;
}

const SelectStrap = inject('store')(
    observer(({ store, selectedStrapCode, setSelectedStrapCode }: SelectStrapProps) => {
        const strapList: TProductModel[] | [] = store?.products.strapList;
        const [isOpen, setOpen] = useState(false);

        if (!strapList?.length) {
            return null;
        }

        const getOption = (value: string) => {
            const foundOption = strapList.find(part => part.id === value);
            return foundOption ? (
                foundOption.productInformation.color
            ) : (
                <span>
                    <FormattedMessage id="product.empty" />
                </span>
            );
        };

        return (
            <FormControl variant="standard" sx={{ margin: '10px 0 10px', width: '100%' }}>
                <InputLabel htmlFor="strap-item">
                    <FormattedMessage id="select.strap" />
                </InputLabel>
                <Select
                    data-testid="strapSelector"
                    labelId="strap-item"
                    open={isOpen}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={selectedStrapCode}
                    name="selectedStrapColor"
                    displayEmpty={false}
                    renderValue={(value: any) => getOption(value)}
                    onChange={e => setSelectedStrapCode(e.target.value as string)}
                    sx={{ overflow: 'hidden' }}
                >
                    {strapList.map((part: TProductModel) => (
                        <MenuItem
                            key={part.id}
                            value={part.id}
                            data-testid={`strap-${part.id}`}
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                            disabled={!part.productInformation.inStock}
                        >
                            <span>
                                <span>{part.productInformation.color}</span>
                            </span>
                            <ProductAvailabilityLabel
                                inStock={part.productInformation.inStock}
                                availableFrom={part.productInformation.availableFrom}
                            />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }),
);

export default SelectStrap;
