import Typography from '@mui/material/Typography';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

const colorMap: Record<string, string> = {
    anthracite: 'Antracite',
    arctic_blue: 'Ocean Blue',
    black: 'Matte Black',
    ice_grey: 'Chalk White',
    turqoise: 'Turquoise',
};

const colorBg: Record<string, string> = {
    chalk_white: '#ffffff',
    fog_white: '#F9F9F9',
    space_grey: '#6F7778',
    stone_grey: '#788387',
    brushed_aluminium: '#e2e2e2',
    matte_black: '#0C0101',
    charcoal_black: '#0C0101',
    asphalt_black: '#3A3A3A',
    jet_black: '#44413f',
    graphite: '#58595B',
    antracite: '#42484A',
    dark_antracite: '#3f454f',
    dutch_orange: '#ff911b',
    steel_blue: '#58737F',
    ocean_blue: '#237597',
    space_blue: '#265f93',
    midnight_blue: '#3c4b63',
    army_green: '#51563C',
    khaki_green: '#aeb1a4',
    timber_green: '#ADAC94',
    turquoise: '#1F7271',
    maple_sand: '#DDCFBD',
    walnut_brown: '#654A37',
    dusty_road: '#ae947c',
    stone_lilac: '#c6b8b8',
    sage_green: '#547B7F',
    desert_copper: '#965743',
    denim_blue: '#769fc0',
    orange: '#da7157',
    dark_brown: '#7a5544',
    light_brown: '#9d6c4a',
};

export const getBackgroundColor = (color?: string) => {
    if (!color) {
        return {
            colorName: '',
            colorCode: 'transparent',
        };
    }

    let colorName = color;
    let colorId: string = color.toLowerCase().replace(' ', '_');

    if (colorMap.hasOwnProperty(colorId)) {
        colorName = colorMap[colorId];
        colorId = colorMap[colorId].toLowerCase().replace(' ', '_');
    }

    return {
        colorName,
        colorCode: colorBg[colorId],
    };
};

interface ColorDotProps extends WithStyles<typeof styles> {
    color: string;
    small?: boolean;
    label?: boolean;
    className?: string;
}

const ColorDot = ({ className, classes, color, small, label }: ColorDotProps) => {
    const { colorName, colorCode } = getBackgroundColor(color);

    return (
        <div className={classes.wrapper}>
            <p className={small ? classes.colorDotSmall : classes.colorDot} style={{ backgroundColor: colorCode }} />

            <Typography
                variant="subtitle1"
                className={classNames(classes.label, className)}
                title={label ? colorName : ''}
            >
                {label ? colorName : ''}
            </Typography>
        </div>
    );
};

function styles() {
    return createStyles({
        wrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        label: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        colorDot: {
            minWidth: '1rem',
            maxWidth: '1rem',
            height: '1rem',
            borderRadius: '50%',
            boxShadow: '1px 1px 5px -3px black',
            marginRight: '1rem',
        },
        colorDotSmall: {
            minWidth: '0.7rem',
            maxWidth: '0.7rem',
            height: '0.7rem',
            borderRadius: '50%',
            boxShadow: '1px 1px 5px -3px black',
            margin: '5px 5px 5px 0',
        },
    });
}

export default withStyles(styles)(ColorDot);
