import { getParent, types } from 'mobx-state-tree';
import { ProductCategoryTypes, SearchType } from '../../utils/constants';
import RootStore from '../RootStore';
import { ProductModel } from './ProductTypes';

export const Battery = types
    .model('battery', {
        code: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
        descriptionDE: types.maybeNull(types.string),
        price: types.maybeNull(types.number),
        priceNextYear: types.maybeNull(types.number),
    })
    .views(self => ({
        translatedDescription(lang: string) {
            return lang === 'de' ? self.descriptionDE : self.description;
        },
    }));

export type TCartItem = typeof CartItemModel.Type;

export const CartItemModel = types
    .model('CartItem', {
        id: types.identifier,
        type: types.maybe(types.enumeration(Object.values(ProductCategoryTypes))),
        battery: types.optional(types.maybeNull(Battery), null),
        saddleAndGrip: types.optional(types.string, ''),
        strap: types.optional(types.string, ''),
        product: types.optional(
            types.late(() => types.reference(ProductModel)),
            '',
        ),
        quantity: types.optional(types.number, 1),
        reference: types.optional(types.string, ''),
        desiredDelivery: types.optional(types.Date, new Date()),
        // only used to load up items from the cart into mobx model
        parentBikeCode: types.optional(types.string, ''),
        parentFrameNumber: types.maybeNull(types.string),
    })
    .volatile(self => ({
        isSelected: false,
    }))
    .views(self => ({
        totalProducts(nextYear?: boolean): number {
            if (self.product && self.battery) {
                const code = self.battery.code;
                if (code) {
                    return self.product.getPrice(code, nextYear) || 0;
                }
            }
            return self.product.price || 0;
        },
    }))
    .views(self => ({
        totalItemsPrice(nextYear: boolean = false): number {
            if (self.product) {
                return self.totalProducts(nextYear) * self.quantity;
            }
            return 0;
        },
    }))
    .actions(self => ({
        increaseQuantity(num: number) {
            this.setQuantity(self.quantity + num);
        },
        setQuantity(num: number) {
            const stockQuantity = self.product.productInformation.stockQuantity ?? 0;
            self.quantity = Math.min(stockQuantity, num);
        },
        setReference(input: any) {
            self.reference = input;
        },
        async loadSparePartInModel() {
            const rootStore: typeof RootStore.Type = getParent(self, 3);
            if (self.parentFrameNumber) {
                await rootStore.spareParts.getSparePartsBy(self.parentFrameNumber, SearchType.FRAME_NUMBER, false);
            } else if (self.parentBikeCode) {
                await rootStore.spareParts.getSparePartsBy(self.parentBikeCode, SearchType.PRODUCT_CODE, false);
            } else if (self.type && self.type === ProductCategoryTypes.MARKETING) {
                await rootStore.marketingMaterials.loadMarketingMaterialsById(self.id);
            } else {
                await rootStore.spareParts.getSparePartsByPartId(self.id);
            }
        },
        toggleSelect() {
            self.isSelected = !self.isSelected;
            const rootStore: typeof RootStore.Type = getParent(self, 3);
            rootStore!.cart.toggleBundleWarning();
        },
    }));
