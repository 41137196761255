import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import BikeIcon from '@mui/icons-material/DirectionsBike';
import LabelIcon from '@mui/icons-material/Label';
import { Button, Card, CardMedia, Dialog, DialogTitle, Grid, IconButton, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import ColorDot from 'src/components/ColorDot';
import CurrencyFormat from 'src/components/currency/Currency';
import DialogContentWithRef from 'src/components/DialogContentWithRef';
import Image from 'src/components/Image';
import PageLoader from 'src/components/PageLoader';
import ProductStockIndicator from 'src/components/ProductStockIndicator';
import { AppModules } from 'src/services/api/models';
import RoleBaseAccess from 'src/services/RoleBaseAccess';
import Store from 'src/stores/RootStore';
import { ProductModel, TProductModel } from 'src/stores/types/ProductTypes';
import AnalyticsEcommerce, { ProductAndPromotionActions } from 'src/utils/analytics-ecommerce';
import { DefaultValues } from 'src/utils/constants';
import { convertImageToLarge } from 'src/utils/convertImageSize';
import ProductForm from './ProductForm';

interface ProductDetailProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    setCartTypeToBike: () => void;
}

const ProductDetail = inject('store')(
    observer(({ classes, store, setCartTypeToBike }: ProductDetailProps) => {
        const selectedBikeHpCode = store!.products.selectedBikeHpCode;
        const selectedBike = store?.products.selectedBike;

        useEffect(() => {
            if (store!.products.isProductDetailDialogOpen && selectedBikeHpCode) {
                store?.products.getConfigurablePartsByProductCode(selectedBikeHpCode);
            }
        }, [store!.products.isProductDetailDialogOpen, selectedBikeHpCode]);

        // Sending modal info into GA
        useEffect(() => {
            if (selectedBike) {
                AnalyticsEcommerce.getInstance()
                    .clear()
                    .addProduct({
                        id: selectedBike.id,
                        name: selectedBike.name,
                        list: 'Products List',
                        brand: 'QWIC',
                        category: `${selectedBike.serie}/${selectedBike.model}`,
                        variant: selectedBike.productInformation?.color,
                        price: selectedBike.getPrice(),
                    })
                    .setAction(ProductAndPromotionActions.DETAIL)
                    .sendPageView();
            }
        }, [store, selectedBike]);

        const displayProduct = (product: TProductModel): JSX.Element => {
            const isFavorite = store!.favorites.isFavorite(product);
            const price = product.getPrice(store!.products.selectedBikeBatteryCode);

            if (ProductModel.is(product) && product.batteryOptions) {
                return (
                    <>
                        <Grid container>
                            <Grid item lg={12}>
                                <Card elevation={0}>
                                    <CardMedia
                                        className={classes.card}
                                        component={(props: any) => (
                                            <div style={{ position: 'relative' }}>
                                                <Image
                                                    {...props}
                                                    src={
                                                        product.productInformation.imageUrl
                                                            ? convertImageToLarge(product.productInformation.imageUrl)
                                                            : undefined
                                                    }
                                                    fallbackSrc={
                                                        product.productInformation.imageUrl
                                                            ? product.productInformation.imageUrl
                                                            : DefaultValues.ImageNotFound
                                                    }
                                                />
                                                {product.isBatteryUpgradePromotion && (
                                                    <Image
                                                        src={DefaultValues.BatteryUpgradeLogo}
                                                        className={classes.promotionLogo}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    />
                                </Card>
                            </Grid>

                            <div className={classes.info}>
                                <div className={classes.infoHeader}>
                                    <Typography gutterBottom variant="h6">
                                        {product.displayName}
                                    </Typography>
                                    <Typography
                                        className={classes.displayFlex}
                                        gutterBottom
                                        variant="h4"
                                        color="primary"
                                    >
                                        {price !== null && <CurrencyFormat value={price} />}
                                        <Button
                                            data-testid={`${isFavorite ? 'remove-from-' : 'add-to-'}favs-indialog`}
                                            onClick={e => {
                                                store!.favorites.toggleProduct(product);
                                                e.stopPropagation();
                                            }}
                                            className={classes.favButton}
                                        >
                                            {isFavorite ? (
                                                <FormattedMessage id="products.removeFromFavorites" />
                                            ) : (
                                                <FormattedMessage id="products.addToFavorites" />
                                            )}
                                            {
                                                <FontAwesomeIcon
                                                    className={classes.favIcon}
                                                    icon={isFavorite ? faStarSolid : faStar}
                                                    size="lg"
                                                />
                                            }
                                        </Button>
                                    </Typography>
                                </div>

                                <div className={classes.infoSpecs}>
                                    {/* TODO color should never be null */}
                                    {product.productInformation.color && (
                                        <ColorDot color={product.productInformation.color} label />
                                    )}
                                    <div className={classes.specItem}>
                                        <LabelIcon />
                                        <Typography variant="body1">{product.productInformation.version}</Typography>
                                    </div>
                                    <div className={classes.specItem}>
                                        <BikeIcon />
                                        <Typography variant="body1">{product.productInformation.size}</Typography>
                                    </div>
                                </div>

                                <div className={classes.availabilityLabel}>
                                    {product.serie === 'Premium Q' && (
                                        <FormattedMessage id="premiumQ.includedCharger">
                                            {text => <span className={classes.premiumQBadge}>{`${text}`}</span>}
                                        </FormattedMessage>
                                    )}

                                    <ProductStockIndicator
                                        isInDialog={true}
                                        isInStock={product.productInformation!.inStock === true}
                                        availableFrom={product.productInformation!.availableFrom}
                                        stockQuantity={product.productInformation!.stockQuantity}
                                    />
                                </div>

                                {RoleBaseAccess.isModuleAllowed(AppModules.ORDER_PRODUCTS) &&
                                !store!.products.isProductDetailDialogFromCart ? (
                                    <ProductForm product={product} setCartTypeToBike={setCartTypeToBike} />
                                ) : (
                                    RoleBaseAccess.isModuleAllowed(AppModules.ORDER_PRODUCTS) && (
                                        <div className={classes.actions}>
                                            <div className={classes.buttons}>
                                                <Button
                                                    onClick={() => store!.products.toggleProductDetailDialog()}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    <FormattedMessage id="dialog.backToCart" />
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </Grid>
                    </>
                );
            }
            return <PageLoader />;
        };

        return (
            <Dialog
                open={store!.products.isProductDetailDialogOpen}
                onClose={() => {
                    store!.products.toggleProductDetailDialog();
                }}
                fullWidth
                maxWidth="sm"
                aria-labelledby="product-detail-dialog-title"
                data-testid="product-detail-dialog"
            >
                <DialogTitle id="product-detail-dialog-title">
                    <Typography variant="h5">{`# ${store!.products.selectedBikeHpCode}`}</Typography>
                    <IconButton
                        data-testid="close-product-dialog"
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={() => store!.products.toggleProductDetailDialog()}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContentWithRef>
                    {store!.products.selectedBikeHpCode !== '' &&
                    store!.products.bikes.has(store!.products.selectedBikeHpCode) ? (
                        displayProduct(selectedBike)
                    ) : (
                        <FormattedMessage id="dialog.productNotFound" />
                    )}
                </DialogContentWithRef>
            </Dialog>
        );
    }),
);

function styles({ spacing, palette }: Theme) {
    return createStyles({
        card: {
            flexGrow: 1,
            maxWidth: '100%',
            padding: spacing(3),
        },
        promotionLogo: {
            position: 'absolute',
            width: '15%',
            top: '2rem',
            right: '2rem',
        },
        info: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        infoHeader: {
            marginBottom: spacing(2),
        },
        infoSpecs: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        specItem: {
            display: 'flex',
            alignItems: 'center',

            '& > svg': {
                marginRight: '1rem',
            },
        },
        availabilityLabel: {
            margin: `${spacing(2)} 0`,
        },
        actions: {
            display: 'flex',
            flexDirection: 'column',
            margin: '1rem 0',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        closeButton: {
            position: 'absolute',
            right: spacing(1),
            top: spacing(1),
            color: palette.grey[500],
        },
        favButton: {
            display: 'flex',
            alignItems: 'center',
            color: palette.secondary.main,
            justifyContent: 'space-between',
        },
        displayFlex: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        favIcon: {
            transition: 'all .3s ease',
            marginLeft: '.5rem',
        },
        premiumQBadge: {
            marginRight: '14px',
            padding: '6px 10px',
            borderRadius: '2px',
            fontSize: '16px',
            backgroundColor: '#d8d8d8',
        },
    });
}

const ProductDetailWithStyles = withStyles(styles)(ProductDetail);

export default ProductDetailWithStyles;
